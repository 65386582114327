<script setup lang="ts">
import { format } from "date-fns"
import { useForm } from "@inertiajs/vue3"
import { computed, ref, watch } from "vue"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "@/components/ui/dialog"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectGroup } from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import { useToast } from "@/components/ui/toast/use-toast"
import { links, types, employees, branches } from "@/store"

const { toast } = useToast()
const wantsToCreateMore = ref(false)
const open = ref(false)
const $name = ref()
const branchEmployees = computed(() => employees.value.filter((employee) => employee.branch_id == form.branch_id || employee.branch_id == null))
const form = useForm({
    branch_id: "1",
    type_id: "1",
    name: "",
    amount: "",
    employee_id: "1",
    date: new Date(),
})

watch(form, () => {
    if (form.type_id == "6" && form.employee_id) {
        const employee = employees.value.find((employee) => employee.id == form.employee_id)
        return (form.name = employee.name)
    }
})

function submit() {
    form.post(links.value.transactions.store_path, {
        onSuccess() {
            if (!wantsToCreateMore.value) {
                form.reset(), (open.value = false)
            } else {
                form.name = ""
                form.amount = ""
                form.employee_id = ""

                $name.value.focus()
            }

            toast({
                description: "Transaction successfully added!",
            })
        },
    })
}
</script>

<template>
    <Dialog v-model:open="open">
        <DialogTrigger>
            <Button> Add transaction </Button>
        </DialogTrigger>
        <DialogContent class="sm:max-w-[625px]">
            <form :action="links.transactions.store_path" @submit.prevent="submit" method="POST">
                <input type="hidden" name="_token" value="" />

                <DialogHeader>
                    <DialogTitle>New Transaction</DialogTitle>
                    <DialogDescription> Add a description about transaction here. </DialogDescription>
                </DialogHeader>
                <div class="grid gap-4 py-4">
                    <div class="flex space-x-4">
                        <FormField name="branch">
                            <FormItem>
                                <FormLabel class="sr-only">Branch</FormLabel>
                                <FormControl>
                                    <Select v-model="form.branch_id">
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a branch" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="(option, index) in branches" :key="index" :value="option.id.toString()">
                                                    {{ option.name }}
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>

                        <FormField name="type">
                            <FormItem>
                                <FormLabel class="sr-only">Type</FormLabel>
                                <FormControl>
                                    <Select v-model="form.type_id">
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="(option, index) in types" :key="index" :value="option.id.toString()">
                                                    {{ option.name }}
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>

                    <div class="grid gap-2">
                        <FormField name="employee" v-if="form.type_id == '6'">
                            <FormItem :error="form.errors.employee_id">
                                <FormLabel>Employee</FormLabel>
                                <FormControl>
                                    <Select v-model="form.employee_id">
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="(option, index) in branchEmployees" :key="index" :value="option.id.toString()">
                                                    {{ option.name }}
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>

                        <FormField name="name">
                            <FormItem :error="form.errors.name">
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input v-model="form.name" ref="$name" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>

                        <FormField name="amount">
                            <FormItem :error="form.errors.amount">
                                <FormLabel>Amount</FormLabel>
                                <FormControl>
                                    <Input type="number" v-model="form.amount" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                </div>
                <Separator class="my-6" />
                <DialogFooter class="flex sm:justify-between">
                    <FormField name="date">
                        <FormItem class="-mt-4" :errors="form.errors.date">
                            <FormLabel class="sr-only">Date</FormLabel>
                            <FormControl>
                                <!-- <Input type="date" v-model="form.date" /> -->
                                <Popover>
                                    <PopoverTrigger>
                                        <Button :variant="'outline'" class="w-[280px] justify-start text-left font-normal" @click.prevent>
                                            <span>{{ form.date ? format(form.date, "PPP") : "Pick a date" }}</span>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent class="w-auto p-0">
                                        <!-- <Calendar v-model="form.date" @update:model-value="() => open = true" /> -->
                                        <Calendar v-model="form.date" @click.prevent="() => (open = true)" />
                                    </PopoverContent>
                                </Popover>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>

                    <div class="[ flex gap-2 items-center ml-auto ]">
                        <label
                            class="[ font-medium px-4 py-2 rounded-md text-xs flex items-center gap-1 select-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-canvas transition duration-100 h-10 ]"
                            :class="{
                                '[ bg-neutral-100/50 hover:bg-neutral-100 focus-within:ring-stroke ]': !wantsToCreateMore,
                                '[ bg-blue-100 text-blue-900 focus-within:ring-accent-base ]': wantsToCreateMore,
                            }"
                        >
                            <input type="checkbox" name="create_more" value="1" v-model="wantsToCreateMore" class="[ sr-only ]" />

                            <div
                                class="[ h-2 w-2 rounded-full ]"
                                :class="{
                                    '[ bg-blue-400 shadow-accent-base shadow-xl ]': wantsToCreateMore,
                                    '[ bg-neutral-400 ]': !wantsToCreateMore,
                                }"
                            ></div>

                            <span class="[ ms-0.5 ]">Add more</span>
                        </label>

                        <Button type="submit">Save</Button>
                    </div>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>
