<script setup lang="ts">
import AppLayout from "@layouts/AppLayout.vue"
import { Trash } from "lucide-vue-next"
import route from "ziggy-js"
import { Inertia } from "@inertiajs/inertia"
import { useForm } from "@inertiajs/vue3"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Switch } from "@/components/ui/switch"

defineOptions({
    layout: [AppLayout],
})

type Props = {
    employees: employees
    shifts: shifts
    payrolls: payrolls
    links: links
    branchId: branchId
    startDate: startDate
    endDate: endDate
}

const { employees, links, shifts, payrolls, branchId, startDate, endDate } = defineProps<Props>()

console.log(payrolls)

const form = useForm({
    date: new Date().toJSON().slice(0, 10),
    employee_id: "",
    time_start: "14:00",
    time_end: "23:00",
    is_holiday: false,
})

function submit() {
    form.post(links.store_path, {
        onSuccess: () => {
            form.reset()
        },
    })
}

function deleteShift(shift) {
    if (confirm("Are you sure you want to delete this shift?")) {
        Inertia.delete(route("shifts.destroy", shift), {
            onSuccess: () => {
                alert("Schedule deleted successfully")
            },
        })
    }
}

const filterForm = useForm({
    branch_id: String(branchId),
    start_date: startDate,
    end_date: endDate,
})

function submitForm() {
    filterForm.get(links.index_path)
}

function getStartAndEndOfWeek() {
    const today = new Date()
    const dayOfWeek = today.getDay()
    const firstDayOfWeek = today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    const lastDayOfWeek = firstDayOfWeek + 6

    const startOfWeek = new Date(today.setDate(firstDayOfWeek))
    const endOfWeek = new Date(today.setDate(lastDayOfWeek))

    startOfWeek.setHours(0, 0, 0, 0)
    endOfWeek.setHours(23, 59, 59, 999)

    return {
        startOfWeek,
        endOfWeek,
    }
}

function formatWeekRange(startDate, endDate) {
    const options = { month: "short", day: "numeric" }
    const start = startDate.toLocaleDateString("en-US", options)
    const end = endDate.toLocaleDateString("en-US", options)

    return `${start} - ${end}`
}
const { startOfWeek, endOfWeek } = getStartAndEndOfWeek()
const formattedWeekRange = formatWeekRange(startOfWeek, endOfWeek)

function numberFormat(number, decimals = 0, decPoint = ".", thousandsSep = ",") {
    const fixedNumber = number.toFixed(decimals)
    const parts = fixedNumber.split(".")

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep)

    return parts.join(decPoint)
}

function handleChange(value) {
    form.is_holiday = value
}
</script>
<template>
    <form @submit.prevent="submitForm" class="mt-4 flex gap-2">
        <Select v-model:model-value="filterForm.branch_id">
            <SelectTrigger>
                <SelectValue placeholder="Select a branch" />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="1">Malabon</SelectItem>
                <SelectItem value="2">La Union</SelectItem>
            </SelectContent>
        </Select>
        <Input type="date" name="start_date" v-model="filterForm.start_date" />
        <Input type="date" name="end_date" v-model="filterForm.end_date" />
        <Button variant="secondary" as="link"><a :href="links.index_path">Clear</a></Button>
        <Button>Save</Button>
    </form>
    <div class="grid grid-cols-2 gap-4 mt-4 mx-auto">
        <div>
            <Card>
                <CardHeader>
                    <CardTitle v-if="shifts.length > 0">{{ shifts[0][0].data.date_range }}</CardTitle>
                    <CardTitle v-else>{{ formattedWeekRange }}</CardTitle>
                    <CardDescription>Card Description</CardDescription>
                </CardHeader>
                <CardContent>
                    <template v-for="shift in shifts">
                        <p class="font-bold">{{ shift[0].data.day }}</p>
                        <div v-for="duty in shift" :key="duty.id">
                            <div v-for="data in duty" :key="data.day" class="flex justify-between items-center text-sm font-medium leading-none">
                                <div>{{ data.employee.name }} - {{ data.start_shift }} to {{ data.end_shift }}</div>
                                <Button variant="ghost" size="icon" @click="deleteShift(data)">
                                    <Trash class="w-3 h-3" />
                                </Button>
                            </div>
                        </div>
                    </template>
                </CardContent>
            </Card>
        </div>
        <div>
            <Card>
                <CardHeader>
                    <CardTitle>Add Shift</CardTitle>
                    <CardDescription>Card Description</CardDescription>
                </CardHeader>
                <CardContent>
                    <form :action="links.store_path" @submit.prevent="submit" method="POST">
                        <div>
                            <Label for="date" class="mb-2">Date</Label>
                            <Input type="date" name="date" v-model="form.date" />
                        </div>
                        <div class="mt-4">
                            <Label for="employee_id" class="mb-2">Employee</Label>
                            <Select name="employeed_id" v-model="form.employee_id">
                                <SelectTrigger>
                                    <SelectValue placeholder="Select employee" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem v-for="employee in employees.data.filter((item) => item.branch_id === Number(filterForm.branch_id))" :key="employee.id" :value="String(employee.id)">
                                        {{ employee.name }}
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div class="mt-4 grid grid-cols-2 gap-2">
                            <div>
                                <Label for="date" class="mb-2">Time Start</Label>
                                <Input type="time" name="time_start" v-model="form.time_start" />
                            </div>
                            <div>
                                <Label for="date" class="mb-2">Time End</Label>
                                <Input type="time" name="time_end" v-model="form.time_end" />
                            </div>
                        </div>
                        <div class="flex justify-between items-center mt-4">
                            <Label class="flex items-center gap-2 text-xs font-normal">
                                <Switch id="is_holiday" name="is_holiday" v-model="form.is_holiday" @update:checked="handleChange" /> Holiday
                            </Label>
                            <Button>Save</Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
            <Card class="mt-4">
                <CardHeader>
                    <CardTitle>Payroll</CardTitle>
                    <CardDescription>Card Description</CardDescription>
                </CardHeader>
                <CardContent>
                    <div class="space-y-8">
                        <div v-for="payroll in payrolls.data" :key="payroll.id" class="flex items-center">
                            <Avatar class="h-9 w-9">
                                <AvatarFallback>A</AvatarFallback>
                            </Avatar>
                            <div class="ml-4 space-y-1">
                                <p class="text-sm font-medium leading-none">{{ payroll.employee.name }}</p>
                                <p class="text-sm text-muted-foreground">{{ payroll.employee.title }}</p>
                            </div>
                            <div class="ml-auto font-medium">₱{{ numberFormat(payroll.salary / 100) }}</div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    </div>
</template>
