<script setup lang="ts">
import { useForm } from "@inertiajs/vue3"
import { Button } from "@/components/ui/button"
import { DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { closeFrame } from "@/components/ui/frames"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectGroup } from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import AppLayout from "@/layouts/AppLayout.vue"

type Props = {
    types: Types
    links: Links
    frame: InertiaFrame
}

defineOptions({
    layout: [AppLayout],
})

const { links, frame } = defineProps<Props>()

const date = new Date()
const form = useForm({
    type_id: "1",
    name: "",
    amount: "",
    date: date.toJSON().slice(0, 10),
})

function submit() {
    form.post(links.store_path, {
        onSuccess: () => {
            form.reset(), closeFrame(frame)
        },
    })
}
</script>

<template>
    <div class="mx-auto max-w-4xl sm:px-6 lg:px-8 my-10">
        <form :action="links.store_path" @submit.prevent="submit" method="POST">
            <input type="hidden" name="_token" value="" />

            <DialogHeader>
                <DialogTitle>New Transaction</DialogTitle>
                <DialogDescription> Add a description about transaction here. </DialogDescription>
            </DialogHeader>
            <div class="grid gap-4 py-4">
                <div class="grid gap-2">
                    <FormField v-slot="{ componentField }" name="name">
                        <FormItem :error="form.errors.name">
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                                <Input type="text" auto-focus v-model="form.name" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="grid gap-2">
                    <Label for="amount">Amount</Label>
                    <Input id="amount" v-model="form.amount" />
                </div>
            </div>
            <Separator class="my-6" />
            <DialogFooter class="flex sm:justify-between">
                <div class="flex items-center space-x-4">
                    <FormField name="type">
                        <FormItem class="-mt-4 w-[150px]">
                            <FormLabel class="sr-only">Type</FormLabel>

                            <Select v-model="form.type_id">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup as="type">
                                        <SelectItem v-for="(option, index) in types" :key="index" :value="option.id.toString()">
                                            {{ option.name }}
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    </FormField>

                    <FormField v-slot="{ componentField, value }" name="date">
                        <FormItem class="-mt-4">
                            <FormLabel class="sr-only">Date</FormLabel>
                            <Input type="date" v-model="form.date" />
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>

                <Button type="submit"> Save </Button>
            </DialogFooter>
        </form>
    </div>
</template>
