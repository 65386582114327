<script setup lang="ts">
import { Colors } from "chart.js"
import Chart from "chart.js/auto"
import { format } from "date-fns"
import { onMounted } from "vue"
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { toHuman } from "@/helpers/formatters/currency"
import AppLayout from "@/layouts/AppLayout.vue"

defineOptions({
    layout: [AppLayout],
})

type Props = {
    totalCurrentBalance: totalCurrentBalance
    malabonFunds: malabonFunds
    launionFunds: launionFunds
    sales: sales
    malabonSales: malabonSales
    launionSales: launionSales
    malabonSales2: malabonSales2
    malabonChart: malabonChart
    launionChart: launionChart
}

const props = defineProps<Props>()

onMounted(() => {
    Chart.register(Colors)

    new Chart(document.getElementById("graph"), {
        type: "bar",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw / 100)
                        },
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        callback: (value) => toHuman(value / 100),
                    },
                },
            },
        },
        data: {
            labels: props.sales.map((sales) => format(new Date(sales.month), "MMM Y")),
            datasets: [
                {
                    label: "Malabon",
                    data: props.sales.map((sales) => sales.malabon),
                },
                {
                    label: "La Union",
                    data: props.sales.map((sales) => sales.launion),
                },
            ],
        },
    })

    new Chart(document.getElementById("malabonSalesGraph"), {
        type: "bar",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw / 100)
                        },
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        callback: (value) => toHuman(value / 100),
                    },
                },
            },
        },
        data: {
            labels: props.malabonSales.map((sales) => format(new Date(sales.x), "MMM Y")),
            datasets: [
                {
                    label: "Net sales",
                    data: props.malabonSales.map((sales) => sales.net),
                    parsing: {
                        yAxisKey: "net",
                    },
                },
                {
                    label: "Cost of goods sold",
                    data: props.malabonSales.map((sales) => sales.cogs),
                    parsing: {
                        yAxisKey: "cogs",
                    },
                },
                {
                    label: "Gross margin",
                    data: props.malabonSales.map((sales) => sales.gm),
                    parsing: {
                        yAxisKey: "gm",
                    },
                },
            ],
        },
    })

    new Chart(document.getElementById("launionSalesGraph"), {
        type: "bar",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw / 100)
                        },
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        callback: (value) => toHuman(value / 100),
                    },
                },
            },
        },
        data: {
            labels: props.launionSales.map((sales) => format(new Date(sales.x), "MMM Y")),
            datasets: [
                {
                    label: "Net sales",
                    data: props.launionSales.map((sales) => sales.net),
                    parsing: {
                        yAxisKey: "net",
                    },
                },
                {
                    label: "Cost of goods sold",
                    data: props.launionSales.map((sales) => sales.cogs),
                    parsing: {
                        yAxisKey: "cogs",
                    },
                },
                {
                    label: "Gross margin",
                    data: props.launionSales.map((sales) => sales.gm),
                    parsing: {
                        yAxisKey: "gm",
                    },
                },
            ],
        },
    })

    new Chart(document.getElementById("malabonSales"), {
        type: "line",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw.y / 100)
                        },
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        callback: (value) => toHuman(value / 100),
                    },
                },
            },
        },
        data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: props.malabonSales2,
        },
    })

    new Chart(document.getElementById("pie"), {
        type: "pie",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw / 100)
                        },
                    },
                },
            },
        },
        data: {
            datasets: [
                {
                    data: props.malabonChart.map((item) => item.amount_in_cents),
                    // backgroundColor: props.malabonChart.map(item => item.color)
                },
            ],
            labels: props.malabonChart.map((item) => item.type),
        },
    })

    new Chart(document.getElementById("launionChart"), {
        type: "pie",
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return toHuman(context.raw / 100)
                        },
                    },
                },
            },
        },
        data: {
            datasets: [
                {
                    data: props.launionChart.map((item) => item.amount_in_cents),
                    // backgroundColor: props.malabonChart.map(item => item.color)
                },
            ],
            labels: props.launionChart.map((item) => item.type),
        },
    })
})
</script>

<template>
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 my-10">
        <div class="flex items-center justify-between space-y-2">
            <h2 class="text-3xl font-bold tracking-tight">Antahan Cafe Overview</h2>
        </div>

        <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-7 mt-10">
            <Card class="col-span-2">
                <CardHeader>
                    <div class="pb-8">
                        <CardDescription class="text-sm text-gray-500"> Total Funds </CardDescription>
                        <CardTitle class="text-3xl">{{ toHuman(totalCurrentBalance / 100) }}</CardTitle>
                    </div>
                    <Separator />
                    <div class="py-8">
                        <CardDescription class="text-sm text-gray-500"> Malabon Funds </CardDescription>
                        <CardTitle class="text-3xl">{{ toHuman(malabonFunds / 100) }}</CardTitle>
                    </div>
                    <Separator />
                    <div class="pt-8">
                        <CardDescription class="text-sm text-gray-500"> La Union Funds </CardDescription>
                        <CardTitle class="text-3xl">{{ toHuman(launionFunds / 100) }}</CardTitle>
                    </div>
                </CardHeader>
                <CardContent> </CardContent>
            </Card>

            <Card class="col-span-5">
                <CardHeader>
                    <CardTitle class="text-md">Sales Overview</CardTitle>
                </CardHeader>
                <CardContent class="px-6">
                    <canvas id="graph"></canvas>
                </CardContent>
            </Card>
        </div>

        <Card class="col-span-5 mt-10">
            <CardHeader>
                <CardTitle class="text-md">Malabon</CardTitle>
            </CardHeader>
            <CardContent class="px-6">
                <canvas id="malabonSalesGraph"></canvas>
            </CardContent>
        </Card>

        <Card class="col-span-5 mt-10">
            <CardHeader>
                <CardTitle class="text-md">Malabon Sales</CardTitle>
            </CardHeader>
            <CardContent class="px-6">
                <canvas id="malabonSales"></canvas>
            </CardContent>
        </Card>

        <Card class="col-span-5 mt-10">
            <CardHeader>
                <CardTitle class="text-md">La Union</CardTitle>
            </CardHeader>
            <CardContent class="px-6">
                <canvas id="launionSalesGraph"></canvas>
            </CardContent>
        </Card>

        <div class="grid grid-cols-2 gap-4">
            <Card class="mt-10">
                <CardHeader>
                    <CardTitle class="text-md flex justify-between items-center">Malabon</CardTitle>
                </CardHeader>
                <CardContent class="px-6">
                    <canvas id="pie"></canvas>
                </CardContent>
            </Card>
            <Card class="mt-10">
                <CardHeader>
                    <CardTitle class="text-md">La Union</CardTitle>
                </CardHeader>
                <CardContent class="px-6">
                    <canvas id="launionChart"></canvas>
                </CardContent>
            </Card>
        </div>
    </div>
</template>
