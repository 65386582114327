<script setup lang="ts">
import AppLayout from "@layouts/AppLayout.vue"
import { useForm } from "@inertiajs/vue3"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

defineOptions({
    layout: [AppLayout],
})

type Props = {
    employees: employees
    shifts: shifts
    links: links
}

const { employees, links, shifts } = defineProps<Props>()

const form = useForm({
    date: new Date().toJSON().slice(0, 10),
    employee_id: "",
    time_start: "15:00",
    time_end: "22:00",
})

function submit() {
    form.post(links.store_path, {
        onSuccess: () => {
            form.reset()
        },
    })
}

console.log(shifts)
</script>
<template>
    <div class="grid grid-cols-2 gap-4 mt-4 mx-auto">
        <Card>
            <CardHeader>
                <CardTitle>{{ shifts[0][0].data.date_range }}</CardTitle>
                <CardDescription>Card Description</CardDescription>
            </CardHeader>
            <template v-for="shift in shifts" class="mb-4">
            <CardContent>
                    <p class="font-bold">{{ shift[0].data.day }}</p>
                    <div v-for="duty in shift" :key="duty.id">
                        <div v-for="data in duty" :key="data.day">{{ data.employees.name }} - {{ data.start_shift }} to {{ data.end_shift }}</div>
                    </div>
            </CardContent>
        </template>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle>Add Shift</CardTitle>
                <CardDescription>Card Description</CardDescription>
            </CardHeader>
            <CardContent>
                <form :action="links.store_path" @submit.prevent="submit" method="POST">
                    <div class="mt-4">
                        <Label for="date" class="mb-2">Date</Label>
                        <Input type="date" name="date" v-model="form.date" />
                    </div>
                    <div class="mt-4">
                        <Label for="employee_id" class="mb-2">Employee</Label>
                        <Select name="employeed_id" v-model="form.employee_id">
                            <SelectTrigger>
                                <SelectValue placeholder="Select employee" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem v-for="employee in employees.data" :key="employee.id" :value="String(employee.id)">
                                    {{ employee.name }}
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div class="mt-4 grid grid-cols-2 gap-2">
                        <div>
                            <Label for="date" class="mb-2">Time Start</Label>
                            <Input type="time" name="time_start" v-model="form.time_start" />
                        </div>
                        <div>
                            <Label for="date" class="mb-2">Time End</Label>
                            <Input type="time" name="time_end" v-model="form.time_end" />
                        </div>
                    </div>
                    <div class="flex justify-end mt-4">
                        <Button>Save</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    </div>
</template>
