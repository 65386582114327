<script setup lang="ts">
import { useForm } from "@inertiajs/vue3"
import AppLayout from "@layouts/AppLayout.vue"
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon, ClockIcon, DotsHorizontalIcon } from "@radix-icons/vue"
import { format } from "date-fns"
import { ref, computed } from "vue"
import func from "../../../../../vue-temp/vue-editor-bridge"

defineOptions({
    layout: [AppLayout],
})

type Props = {
    shifts: Shifts,
    employees: Employees,
    branch: Branche,
    links: Links,
    start_shift: start_shift,
    end_shift: end_shift,
}

const { branch, start_shift, end_shift, shifts } = defineProps<Props>()

 console.log(shifts)

</script>

<template>
    <h1 class="font-bold">{{ branch.name }} Sched</h1>
    <p>{{ start_shift }} to {{ end_shift }}</p>
    
    <template v-for="shift in shifts" :key="shift.id">
        <div>
            <p>{{ shift[0].data.day }}</p>
            <template v-for="barista in shift" :key="barista.id">
                <p>{{ barista.data.start_shift }} to {{ barista.data.end_shift }} {{ barista.data.employee.name }}</p>
            </template>
        </div>
    </template>
</template>
